import React from 'react';
import { withRouter, Link } from 'react-router-dom'

// import logo from '../assets/Logo.svg'

import styles from './Contact.module.scss';

const Faq = ({ history }) => {
    React.useEffect(() => {
        window.scrollTo(0, 0)
    })

    return (
        <div className={styles.layout}>
            <div className={styles.topBar}>
                <Link to="/">
                    <img src={"https://res.cloudinary.com/dgxze1o8e/image/upload/v1567761859/gpt_logo_svg.svg"} alt="" className={styles.logo} />
                </Link>
            </div>
            <span className={styles.title}>
                Contact us
            </span>
            <div className={styles.section}>
                You can DM us directly on instagram @golden_pricetag or send us a mail at contact@goldenpricetag.com
            </div>
        </div>
    )
}

export default withRouter(Faq)