import React from 'react';
import { withRouter, Link } from 'react-router-dom'

// import logo from '../assets/Logo.svg'

import styles from './CheckoutFail.module.scss';

const CheckoutFail = ({ history }) => {
    return (
        <div className={styles.layout}>
            CHECKOUT FAIL
        </div>
    )
}

export default withRouter(CheckoutFail)