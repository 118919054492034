import React from 'react';
import { withRouter } from 'react-router-dom'
import { Link } from "react-router-dom";

// import logo from '../assets/Logo.svg'
import IconBack from '../assets/IconBack';

import styles from './Topbar.module.scss';

const Topbar = ({ history }) => {
    return (
        <div className={styles.layout}>
            <div className={styles.backWrapper}>
                <Link to="/" className={styles.back}>
                    <IconBack />
                    back
            </Link>
            </div>

            {/* <button onClick={history.goBack} className={styles.back}>BACK</button> */}
            <img src={"https://res.cloudinary.com/dgxze1o8e/image/upload/v1567761859/gpt_logo_svg.svg"} className={styles.logo} alt="" />
        </div>
    )
}

export default withRouter(Topbar)