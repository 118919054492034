export const separateStr = str => {
    const separator = '.'
    let i = str.length;
    let j = 0;
    let finalStr = '';
    while (i >= 0) {
        if (j === 3) {
            finalStr = str.slice(i, i + 3) + separator + finalStr;
            j = 0;
        }
        i -= 1;
        j += 1;
    }
    if (j > 0) {
        finalStr = str.slice(0, i + j) + separator + finalStr;
    }
    if (finalStr.charAt(finalStr.length - 1) === separator)
        finalStr = finalStr.substring(0, finalStr.length - 1);
    if (finalStr.charAt(0) === separator)
        finalStr = finalStr.substring(1, finalStr.length);
    return finalStr.trim();
}

export const cleanNbrStr = str => {
    let finalStr = '';
    for (let i = 0; i < str.length; i += 1) {
        if (str.charAt(i) >= '0' && str.charAt(i) <= '9') {
            finalStr += str.charAt(i);
        }
    }
    return finalStr;
}