import React from 'react';
import { withRouter, Link } from 'react-router-dom'

// import logo from '../assets/Logo.svg'

import styles from './Faq.module.scss';

const Faq = ({ history }) => {
    React.useEffect(() => {
        window.scrollTo(0, 0)
    })

    return (
        <div className={styles.layout}>
            <div className={styles.topBar}>
                <Link to="/">
                    <img src={"https://res.cloudinary.com/dgxze1o8e/image/upload/v1567761859/gpt_logo_svg.svg"} alt="" className={styles.logo} />
                </Link>
            </div>
            <span className={styles.title}>
                FAQ
            </span>
            <div className={styles.section}>
                <div className={styles.question}>How does Golden Price Tag work?</div>
                You choose your picture, caption and price. After you paid you’ll receive your picture which is now modified with our price tag and we’ll upload it to our website and instagram account.
            </div>
            <div className={styles.section}>
                <div className={styles.question}>How much does it cost?</div>
                That is up to you. The minimum amount is 1000$. The maximum is 999.999$. If you click on a picture of a gold member you can see how much that person paid.
            </div>
            <div className={styles.section}>
                <div className={styles.question}>Can I choose whatever picture I want?</div>
                Before we upload it we’ll review every picture and decide if it holds up to our gold standard. If it doesn’t we won’t upload it but no worries you’ll get your money back.
            </div>
            <div className={styles.section}>
                <div className={styles.question}>For how long will my picture be up on the site?</div>
                As long as you want. If you wish to take it down just write us a mail or dm us on instagram. This also applies for every other question you might have.
    
            </div>
        </div>
    )
}

export default withRouter(Faq)