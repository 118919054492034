import React from 'react'
import Cropper from 'react-easy-crop'
import NateFileUploader from '../NateFileUploader/NateFileUploader';

import UserIcon from "../assets/user2.svg"
import logo from "../assets/Logo.png"

import styles from './PhotoUploader.module.scss'

const PhotoUploader = ({ fileUrl, setFileUrl, croppedAreaPixels, setCroppedAreaPixels }) => {

    const [crop, setCrop] = React.useState({ x: 0, y: 0 })
    const [zoom, setZoom] = React.useState(1)
    const [minZoom, setMinZoom] = React.useState(1)

    const onCropComplete = React.useCallback((croppedArea, croppedAreaPixels) => {
        setCroppedAreaPixels(croppedAreaPixels);
    }, [])

    const onCropChange = newCrop => {
        if (zoom < minZoom) {
            setZoom(minZoom)
        }
        setCrop(newCrop)
    }

    const onFileChange = (e) => {
        if (!e.target.files[0])
            return;
        // const captionInput = document.getElementById("caption-input");
        // if (captionInput)
        //     captionInput.focus();
        const img = new Image();
        img.onload = function () {
            const max = this.width > this.height ? this.width : this.height;
            const min = this.width <= this.height ? this.width : this.height;
            setMinZoom(max / min)
            setCroppedAreaPixels({ width: min, height: min, x: max === this.width ? (this.width - min) / 2 : 0, y: max === this.height ? (this.height - min) / 2 : 0 });
        };
        img.src = URL.createObjectURL(e.target.files[0]);
        setFileUrl(URL.createObjectURL(e.target.files[0]));
    }

    const cropSize = {
        width: window.innerWidth * (window.innerWidth < 736 ? 0.9 : 0.4),
        height: window.innerWidth * (window.innerWidth < 736 ? 0.9 : 0.4)
    }

    return (
        <div >

            <NateFileUploader style={{ visibility: fileUrl ? 'none' : 'visible' }} onChange={onFileChange} name="photo" accept="image/x-png,image/jpeg">
                <img src={UserIcon} className={styles.userIcon} alt="user-icon" />
            </NateFileUploader>
            {fileUrl &&
                <div>
                    <Cropper
                        cropSize={cropSize}
                        restrictPosition={true}
                        image={fileUrl}
                        crop={crop}
                        zoom={zoom}
                        aspect={1}
                        onCropChange={onCropChange}
                        onCropComplete={onCropComplete}
                        onZoomChange={setZoom}
                        showGrid={false}
                        style={{ cropAreaStyle: { border: "none" } }}
                        minZoom={minZoom}
                        maxZoom={minZoom + 3}
                        initialCroppedAreaPixels={croppedAreaPixels}
                    />
                    <img src={"https://res.cloudinary.com/dgxze1o8e/image/upload/v1567760775/gpt_logo.png"} className={styles.logoOverlay} alt="overlay" />
                </div>
            }
        </div>
    )
}

export default PhotoUploader