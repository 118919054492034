import React from 'react';
import { Router, Route, Redirect } from "react-router-dom";
import { createBrowserHistory } from "history";

import './App.global.css';
import styles from './App.module.scss';
import ScrollManager from './ScrollManager/ScrollManager';
import Viewer from './Viewer/Viewer';
import CheckoutSuccess from './CheckoutSuccess/CheckoutSuccess';
import CheckoutFail from './CheckoutFail/CheckoutFail';
import Faq from './Faq/Faq';
import Terms from './Terms/Terms';
import PrivacyPolicy from './PrivacyPolicy/PrivacyPolicy';
import Contact from './Contact/Contact';

const history = createBrowserHistory()

class App extends React.Component {
  state = {
    modal: false
  }

  prout = () => {
    this.setState({ modal: true })
    document.body.setAttribute("style", "overflow: hidden;")
  }

  caca = () => {
    this.setState({ modal: false })
    document.body.removeAttribute("style")
  }

  render() {
    return (
      <Router history={history}>
        <Route
          path="/"
          exact
          component={ScrollManager}
        />
        <Route
          path={`/post/:postId`}
          component={Viewer}
        />
        <Route
          path={`/checkoutSuccess`}
          component={CheckoutSuccess}
        />
        <Route
          path={`/checkoutFail`}
          component={CheckoutFail}
        />
        <Route
          path={`/faq`}
          component={Faq}
        />
        <Route
          path={`/contact`}
          component={Contact}
        />
        <Route
          path={`/terms`}
          component={Terms}
        />
        <Route
          path={`/privacypolicy`}
          component={PrivacyPolicy}
        />
        {/* <Redirect to="/" /> */}
      </Router>
    )
  }
}

export default App;
