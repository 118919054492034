// import one from './one.jpg'
// import two from './two.jpg'
// import three from './three.jpg'
// import four from './four.jpg'
// import five from './five.jpg'
// import six from './six.jpg'
// import seven from './seven.png'
// import eight from './eight.jpg'
// import nine from './nine.jpg'

const posts = [
    {
        id: "BAYDDgwODQICDQkLBA8DBg",
        image: "BAYDDgwODQICDQkLBA8DBg",
        thumbnail: "BAYDDgwODQICDQkLBA8DBg",
        amount: "1000",
        caption: "Still less expensive than the champagne that day @the_victorious_b.i.g",
        instagramUserUrl: "https://www.instagram.com/kyliejenner/",
        instagramPostUrl: "https://www.instagram.com/p/BzksfPmHi1X/",
        created: "July 6, 2019"
    },
    {
        id: "BA4CDAYNCQcFCAQCBAcCDA",
        image: "BA4CDAYNCQcFCAQCBAcCDA",
        thumbnail: "BA4CDAYNCQcFCAQCBAcCDA",
        amount: "2500",
        caption: "dark clouds but a bright future @katjakleiner__",
        instagramUserUrl: "https://www.instagram.com/pantheraoncca/",
        instagramPostUrl: "https://www.instagram.com/p/BzBYtOLHThj/",
        created: "June 22, 2019"
    },
    {
        id: "DgYIDwIFAg8NDQgGBAsABQ",
        image: "DgYIDwIFAg8NDQgGBAsABQ",
        thumbnail: "DgYIDwIFAg8NDQgGBAsABQ",
        amount: "1000",
        caption: "",
        instagramUserUrl: "https://www.instagram.com/pantheraoncca/",
        instagramPostUrl: "https://www.instagram.com/p/BzBYtOLHThj/",
        created: "June 22, 2019"
    },
    // {
    //     id: "AAkGDwYNDAwCAAQPBAQKBg",
    //     image: "AAkGDwYNDAwCAAQPBAQKBg",
    //     thumbnail: "AAkGDwYNDAwCAAQPBAQKBg",
    //     amount: "3000",
    //     caption: "A cold champagne can't wait @emmanuel.aug_",
    //     instagramUserUrl: "https://www.instagram.com/veronikaorchid",
    //     instagramPostUrl: "https://www.instagram.com/p/BzQWQNcHo7Y/",
    //     created: "June 28, 2019"
    // },
    {
        id: "DQMMCAELBAAMCQIHBAIHAg",
        image: "DQMMCAELBAAMCQIHBAIHAg",
        thumbnail: "DQMMCAELBAAMCQIHBAIHAg",
        amount: "1000",
        caption: "Did I really just spend $1000 on this picture? Yeah whatever. @leonard_weinstock",
        instagramUserUrl: "https://www.instagram.com/kyliejenner/",
        instagramPostUrl: "https://www.instagram.com/p/BzksfPmHi1X/",
        created: "July 6, 2019"
    },
    // {
    //     id: "five",
    //     image: "five",
    //     thumbnail: "five",
    //     amount: "8000",
    //     caption: "Friday vibes on the yacht 😎 @veronikaorchid Bring on the weekend 😋",
    //     instagramUserUrl: "https://www.instagram.com/veronikaorchid",
    //     instagramPostUrl: "https://www.instagram.com/p/BzQWQNcHo7Y/",
    //     created: "June 28, 2019"
    // },
    // {
    //     id: "six",
    //     image: "six",
    //     thumbnail: "six",
    //     amount: "6000",
    //     caption: "A woman with good shoes is never ugly 😏 @pantheraoncca",
    //     instagramUserUrl: "https://www.instagram.com/pantheraoncca/",
    //     instagramPostUrl: "https://www.instagram.com/p/BzBYtOLHThj/",
    //     created: "June 22, 2019"
    // },
    // {
    //     id: "seven",
    //     image: "seven",
    //     thumbnail: "seven",
    //     amount: "10000",
    //     caption: "Car choices for @kyliejenner",
    //     instagramUserUrl: "https://www.instagram.com/kyliejenner/",
    //     instagramPostUrl: "https://www.instagram.com/p/BzksfPmHi1X/",
    //     created: "July 6, 2019"
    // },
    // {
    //     id: "eight",
    //     image: "eight",
    //     thumbnail: "eight",
    //     amount: "8000",
    //     caption: "Friday vibes on the yacht 😎 @veronikaorchid Bring on the weekend 😋",
    //     instagramUserUrl: "https://www.instagram.com/veronikaorchid",
    //     instagramPostUrl: "https://www.instagram.com/p/BzQWQNcHo7Y/",
    //     created: "June 28, 2019"
    // },
    // {
    //     id: "nine",
    //     image: "nine",
    //     thumbnail: "nine",
    //     amount: "6000",
    //     caption: "A woman with good shoes is never ugly 😏 @pantheraoncca",
    //     instagramUserUrl: "https://www.instagram.com/pantheraoncca/",
    //     instagramPostUrl: "https://www.instagram.com/p/BzBYtOLHThj/",
    //     created: "June 22, 2019"
    // }
]

export default posts;