import React from 'react';
import cx from "classnames";
import ProgressiveImage from "react-progressive-image";
import { Link } from "react-router-dom";

import blackPixel from './black_pixel.jpg'
// import logo from '../assets/Logo.png'
import posts from "../posts/posts";
import styles from './Gallery.module.scss';

// import './Gallery.css'

function chunk(array, chunkSize) {
    var R = [];
    for (var i = 0; i < array.length; i += chunkSize)
        R.push(array.slice(i, i + chunkSize));
    return R;
}

const Thumbnail = (props) => {
    const { thumbnail, id } = props.post;

    return (
        <div className={styles.thumbnailContainer} tabIndex="0">
            <Link to={`/post/${id}`}>
                <ProgressiveImage src={"https://res.cloudinary.com/dgxze1o8e/image/upload/c_scale,q_auto:eco,w_500/" + id + ".jpg"} placeholder="">
                    {(src, loading) => {
                        return (<div className={styles.thumbnailWrapper} style={{ opacity: loading ? 0 : 1 }}>
                            <img src={loading ? blackPixel : src} alt="" className={cx(styles.thumbnailImage)} />
                            <img src={"https://res.cloudinary.com/dgxze1o8e/image/upload/w_200,c_scale/gpt_logo.png"} className={styles.logoOverlay} alt="" />
                        </div>)
                    }}
                </ProgressiveImage>
                {/* <img src={cl.url(id + '.jpg', { width: 500, height: 500, crop: "scale" })} className={styles.thumbnailImage} alt="" draggable="false" /> */}
            </Link>
        </div>
    )
}

const Row = ({ posts }) => {
    const allThreeOfThem = posts.map(post => (<Thumbnail post={post} key={post.created} />));
    let toAdd = 3 - posts.length;
    while (toAdd > 0) {
        allThreeOfThem.push(<div className={styles.thumbnailContainer} />)
        toAdd -= 1;
    }
    return (
        <div className={cx(styles.box, styles.row)}>
            {allThreeOfThem}
        </div>
    )
}

const Gallery = (props) => {
    return (
        <div className={styles.layout}>
            <h2>gold members</h2>
            <div className={styles.container}>
                <div className={styles.gallery}>
                    {
                        chunk(posts, 3).map((row, index) => <Row posts={row} key={index} />)
                    }
                </div>
            </div>
        </div>
    )
}

export default Gallery