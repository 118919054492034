import React from 'react';
import Plx from 'react-plx';
import { Route } from "react-router-dom";

import Welcome from '../Welcome/Welcome';
import Gallery from '../Gallery/Gallery';
import NewPost from '../NewPost/NewPost';
import Footer from '../Footer/Footer';

import styles from './ScrollManager.module.scss';
import Viewer from '../Viewer/Viewer';

const appear = {
  startValue: 0,
  endValue: 1,
  property: 'opacity',
}

const ScrollManager = ({ match }) => {

  const height = window.innerHeight;
  const WelcomeParallaxData = [
    {
      start: 0,
      duration: "50vh",
      properties: [
        {
          startValue: 1,
          endValue: 0,
          property: 'opacity',
        }
      ],
    },
  ];

  const GalleryParallaxData = [
    // {
    //   start: 0,
    //   duration: "60vh",
    //   properties: [
    //     {
    //       startValue: 0,
    //       endValue: (height * 0.3) * -1,
    //       property: 'translateY',
    //     }
    //   ],
    // },
    {
      start: "10vh",
      duration: "60vh",
      properties: [appear],
    },
  ];
  const NewPostParallaxData = [
    {
      start: "#newPost-container",
      duration: "60vh",
      properties: [appear],
    },
  ];
  const FooterParallaxData = [
    {
      start: "#footer-container",
      duration: "#footer-container",
      properties: [appear],
    },
  ];
  return (
    <div id="scroller">
      <div
        id="welcome-container">
        <Plx
          parallaxData={WelcomeParallaxData}
        // easing={'easeInOut'}
        >
          <Welcome />
        </Plx>
      </div>
      <div id="gallery-container">
        <Plx
          className={styles.willChange}
          parallaxData={GalleryParallaxData}
          style={{ willChange: "transform", opacity: 0 }}
        >
          <Gallery />
        </Plx>
      </div>
      <div id="newPost-container">
        <Plx
          parallaxData={NewPostParallaxData}
          style={{ 
            // marginTop: (height * 0.3) * -1,
             opacity: 0 }}
        >
          <NewPost />
        </Plx>
      </div>
      <div id="footer-container">
        <Plx
          parallaxData={FooterParallaxData}
          style={{ opacity: 0 }}
        >
          <Footer />
        </Plx >
      </div>
      {/* <Route
        path={`/post/:postId`}
        component={Viewer}
      /> */}
    </div>
  )
}

export default ScrollManager;
