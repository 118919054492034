import React from 'react';
import Loader from 'react-loader-spinner'
import { CSSTransition } from 'react-transition-group';

import styles from './LoaderScreen.module.scss';

function vh(v) {
    var h = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);
    return (v * h) / 100;
}

function vw(v) {
    var w = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
    return (v * w) / 100;
}

function vmin(v) {
    return Math.min(vh(v), vw(v));
}

function vmax(v) {
    return Math.max(vh(v), vw(v));
}

const LoaderScreen = ({ show }) => {
    React.useEffect(() => {
        document.body.style.overflow = "hidden";
        return (() => {
            document.body.style.overflow = "";
        })
    })

    return (
    //     <CSSTransition
    //     in={show}
    //     timeout={300}
    //     mountOnEnter
    //     unmountOnExit
    //     onEnter={() => setShowButton(false)}
    //     onExited={() => setShowButton(true)}
    //   >
        <div className={styles.loader}>
            <Loader
                type="Oval"
                color="#C4A155"
                height={vmin(12)}
                width={vmin(12)}
            />
        </div>
        // </CSSTransition>
    )
}

export default LoaderScreen