import React from 'react';
import queryString from 'query-string';
import { withRouter, Link } from 'react-router-dom'

// import logo from '../assets/Logo.svg'

import styles from './CheckoutSuccess.module.scss';

function forceDownload(url, fileName) {
    var xhr = new XMLHttpRequest();
    xhr.open("GET", url, true);
    xhr.responseType = "blob";
    xhr.onload = function () {
        var urlCreator = window.URL || window.webkitURL;
        var imageUrl = urlCreator.createObjectURL(this.response);
        var tag = document.createElement('a');
        tag.href = imageUrl;
        tag.download = fileName;
        document.body.appendChild(tag);
        tag.click();
        document.body.removeChild(tag);
    }
    xhr.send();
}

const CheckoutFail = ({ history }) => {
    const [overlayedUrl, setOverlayedUrl] = React.useState(null)

    React.useEffect(() => {
        const searchParams = queryString.parse(history.location.search);
        if (!searchParams.id) {
             history.replace('/');
             return;
        }
        setOverlayedUrl("https://res.cloudinary.com/dgxze1o8e/image/upload/" + searchParams.id + ".jpg")
    }, [history])

    const onImageClick = () => {
        if (overlayedUrl) {
            forceDownload(overlayedUrl, "goldenpricetag.jpg")
        }
    }

    return (
        <div className={styles.layout}>
            <Link to="/">
                <img className={styles.logo} src={"https://res.cloudinary.com/dgxze1o8e/image/upload/v1567761859/gpt_logo_svg.svg"} alt="" />
            </Link>
            <span className={styles.congrats}>Congratulations on purchasing your price tag!</span>
            <div className={styles.azertyu}>
                {/* <a href={overlayedUrl} download>
                    <img src={overlayedUrl} className={styles.photo}/>
                </a> */}
                <img src={overlayedUrl} className={styles.photo} onClick={onImageClick} alt="final post"/>
            </div>
            <span className={styles.message}>Click on the image to save it.</span>
            <span className={styles.message}>Your post is under review and will be online very soon.</span>

        </div>
    )
}

export default withRouter(CheckoutFail)