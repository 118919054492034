import React from 'react';
// import ProgressiveImage from "react-progressive-image";
import cx from "classnames";

// import logo from '../assets/Logo.svg';
// import InstagramLogo from '../assets/InstagramLogo.svg';

import styles from './Welcome.module.scss';

const Welcome = (props) => {
  const [appeared, setAppeared] = React.useState(false)

  React.useEffect(()=> {
    if (!appeared)
      setAppeared(true)
  }, [appeared])

  const onClickLogo = () => {
    const gallery = document.getElementById('gallery-container');
    if (gallery)
      gallery.scrollIntoView({behavior: "smooth"})
  }

  return (
    <div className={cx(styles.layout, appeared ? styles.appear : '')} >
      {/* <div className={styles.goldBorder} /> */}
      <img src={"https://res.cloudinary.com/dgxze1o8e/image/upload/v1567761859/gpt_logo_svg.svg"} className={styles.logo} alt="golden pricetag logo" onClick={onClickLogo}/>
      <a href="https://www.instagram.com/golden_pricetag/" target="_blank" rel="noopener noreferrer" className={styles.instagramLogoLink}>
        <img src={"https://res.cloudinary.com/dgxze1o8e/image/upload/v1567763266/instagram_logo_svg.svg"} className={styles.instagramLogo} alt="instagram logo" />
      </a>
    </div>
  )
}

export default Welcome