import React from 'react';
import { Link } from 'react-router-dom'

// import InstagramLogo from '../assets/InstagramLogo.svg';
import styles from './Footer.module.scss';

const features = [
    {
        img: "https://res.cloudinary.com/dgxze1o8e/image/upload/v1569605817/petapixel_rwkwag.png",
        link: "https://petapixel.com/2019/09/05/rich-kids-are-paying-thousands-to-have-their-photos-featured-on-this-site/"
    },
    {
        img: "https://res.cloudinary.com/dgxze1o8e/image/upload/v1569605817/mashable_ctshel.png",
        link: "https://mashable.com/article/golden-price-tag-1000-instagram-feature/"
    },
    {
        img: "https://res.cloudinary.com/dgxze1o8e/image/upload/ladbible.png",
        link: "http://www.ladbible.com/news/weird-rich-kids-are-spending-thousands-to-be-on-new-instagram-account-20190910"
    },
    {
        img: "https://res.cloudinary.com/dgxze1o8e/image/upload/gq.png",
        link: "https://www.gq.com.au/success/finance/the-instagram-account-where-rich-kids-pay-thousands-just-for-a-shoutout/news-story/cbedfaf2d3a6b163d9120d209ed8c55c"
    },
    {
        img: "https://res.cloudinary.com/dgxze1o8e/image/upload/v1569605817/vanityfair_vfn8ix.png",
        link: "https://www.vanityfair.fr/savoir-vivre/story/-golden-price-tag-le-business-instagram-dope-a-la-vanite/10319"
    },
    {
        img: "https://res.cloudinary.com/dgxze1o8e/image/upload/posta.png",
        link: "https://www.posta.com.tr/zenginler-bu-instagram-hesabinda-yer-almak-icin-binlerce-dolar-oduyor-haber-fotograf-2205017"
    },
]

// const Featured = ( {img, link} ) => {
//     return <
// }

const Footer = (props) => {
    return (
        <>
            <div className={styles.azertyu}>
                <a href="https://www.instagram.com/golden_pricetag/" target="_blank" rel="noopener noreferrer" className={styles.link}>
                    <img src={"https://res.cloudinary.com/dgxze1o8e/image/upload/v1567763266/instagram_logo_svg.svg"} className={styles.instagramLogo} alt="instagram logo" />
                </a>


                <Link to="/faq">
                    <h2 className={styles.link}>FAQ</h2>
                </Link>
                <Link to="/contact">
                    <h2>CONTACT US</h2>
                </Link>
                <Link to="/privacypolicy">
                    <h2 className={styles.link}>PRIVACY POLICY</h2>
                </Link>
                <div className={styles.featuredOn}>
                    <div>As featured on</div>
                    {features.map(f =>
                        <a href={f.link} target="_blank" rel="noopener noreferrer" >
                            <img src={f.img} className={styles.featured} alt="" />
                        </a>
                    )}
                </div>
            </div>
            <div className={styles.credits}>© 2019 GOLDEN PRICE TAG</div>
        </>
    )
}

export default Footer