import axios from 'axios';
import cx from "classnames";
/* global Stripe Log*/
import React from 'react';
import { Link } from "react-router-dom";

import { cleanNbrStr, separateStr } from '../utils';
import getCroppedImg from './cropImage'
import LoaderScreen from './LoaderScreen';
import styles from './NewPost.module.scss';
import PhotoUploader from './PhotoUploader';

const stripePublicKey = process.env.NODE_ENV === 'production' ? 'pk_live_U75mDgSUyFvzPyDH2AcXoaPf00NLTPun8f' : 'pk_test_lVcATeTiFs94pLNQfi4TZQ8700A4asrDji'
const minPrice = 1000;
const maxPrice = 1000000;

const NewPost = (props) => {
  const [loading, setLoading] = React.useState(false);
  const [minimumMsg, setMinimumMsg] = React.useState(false);
  const [fileUrl, setFileUrl] = React.useState(null);
  const [croppedAreaPixels, setCroppedAreaPixels] = React.useState(null);
  const [caption, setCaption] = React.useState('');
  const [price, setPrice] = React.useState('');
  const [termsAgreed, setTermsAgreed] = React.useState(false);

  const onFormSubmit = async (e) => {
    const referralInput = document.querySelector('[name="referral"]')
    e.preventDefault();

    if (notReadyToPost()) return;

    const formData = new FormData();
    if (fileUrl === null) {
      alert('Please upload a photo for the post')
      return;
    }

    setLoading(true);
    const cropped = await getCroppedImg(fileUrl, croppedAreaPixels);
    formData.append('photo', cropped);
    formData.append('caption', caption);
    formData.append('price', parseInt(cleanNbrStr(price)));
    formData.append('referral', referralInput ? referralInput.value : "none");
    const config = {
      headers: {
        'content-type': 'multipart/form-data'
      },
      onUploadProgress: function (progressEvent) {
        const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
        console.log("percentCompleted: " + percentCompleted)
      }
    };
    axios
      .post("https://golden-price-tag.herokuapp.com/api/posts/upload", formData, config)
      .then((res) => {
        setLoading(false);
        console.log("res from server", res)
        const stripe = Stripe(stripePublicKey);
        stripe.redirectToCheckout({ sessionId: res.data.checkoutSessionId })
          .then(function (result) {
            console.warn("GOT FROM STRIPE", result)
            // If `redirectToCheckout` fails due to a browser or network
            // error, display the localized error message to your customer
            // using `result.error.message`.
          });
        console.log("----- got in return ", res)
      }).catch((error) => {
        setLoading(false);
        console.error("error", error)
        alert("There was a problem.");
      });
  }

  const onCaptionChange = e => {
    // console.log("caption: ", e.target.value)
    setCaption(e.target.value)
  }

  const onPriceChange = e => {
    // console.log("price: ", e.target.value)
    if (e.target.value.length === 0) {
      setPrice('')
      return;
    }
    const number = cleanNbrStr(e.target.value);
    const parsedNumber = parseInt(number, 10);
    // console.log("parsed Number: ", parsedNumber)
    if (isNaN(parsedNumber)) {
      setPrice('')
    } else if (parsedNumber < maxPrice) {
      setPrice('$' + separateStr(number));
      if (minimumMsg && parsedNumber >= minPrice) {
        setMinimumMsg(false)
      }
    }
    else
      setPrice("$999.999");
  }

  const onPriceBlur = () => {
    if (parseInt(cleanNbrStr(price)) < minPrice)
      setMinimumMsg(true);
  }

  const notReadyToPost = () => {
    if (parseInt(cleanNbrStr(price)) < minPrice)
      return true;
    if (fileUrl === null)
      return true;
    if (price.length === 0)
      return true;
    if (!termsAgreed)
      return true;
    return false
  }

  const onClickChange = (e) => {
    e.preventDefault();
    const input = document.getElementById("file-input");
    if (input) {
      input.click();
    }
  }

  const toggleTerms = e => {
    // console.log(e.target.checked)
    setTermsAgreed(e.target.checked)
  }

  return (
    <div className={styles.layout}>
      <form onSubmit={onFormSubmit} className={styles.form} data-rewardful>
        <h2 className={styles.text}>create your golden price tag</h2>
        <div className={styles.photoContainer}>
          <PhotoUploader
            fileUrl={fileUrl}
            setFileUrl={setFileUrl}
            croppedAreaPixels={croppedAreaPixels}
            setCroppedAreaPixels={setCroppedAreaPixels} />
        </div>
        <button className={styles.button} onClick={onClickChange}>{fileUrl ? "Change picture" : "Upload a picture"}</button>
        <div className={styles.captionInputContainer}>
          <textarea
            className={styles.captionInput}
            placeholder="Write a caption... and don't forget to tag yourself"
            name="caption"
            onChange={onCaptionChange}
            value={caption}
            id="caption-input"
          />
        </div>
        <div className={styles.pricetagWrapper}>
          <div className={cx(styles.pricetagContainer, price.length === 0 ? '' : styles.pricetagContainerActive)}>
          {/* <span>$</span> */}
            <input
              className={styles.priceInput}
              placeholder="Your price"
              name="priceTag"
              type="text"
              onChange={onPriceChange}
              value={price}
              onBlur={onPriceBlur}
            />
          </div>
          {minimumMsg && <span className={styles.minimumPrice}>The minimum price is 1000$</span>}
        </div>
        {/* <div className={styles.terms}>
          <span className={styles.minimumPrice}>The minimum price is 1000$</span>
        </div> */}
        <div className={styles.terms}>
          <input id="agree-terms-checkbox" checked={termsAgreed} type="checkbox" className={styles.styledCheckbox} onChange={toggleTerms} />
          <label htmlFor="agree-terms-checkbox"></label>
          <span className={styles.termsCaption}>I agree to the <Link to="/terms"><u>terms and conditions</u></Link></span>
        </div>
        <button
          disabled={notReadyToPost()}
          className={cx(styles.button, styles.createButton)}
          type="submit">Create</button>
      </form>
      {loading && <LoaderScreen />}
    </div>
  )
}

export default NewPost