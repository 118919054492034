import React from 'react';

import styles from './NateFileUploader.module.css'


const NateFileUploader = ({ children, onChange, accept, name }) => {
   let uploaderRef = null;

   const onClick = () => {
      uploaderRef.click();
   };

   return (
      <div onClick={onClick} className={styles.uploaderContainer} >
         {children}
         <input
            ref={input => {
               uploaderRef = input;
            }}
            type="file"
            id="file-input"
            name={name ? name : "file-input"}
            accept={accept}
            onChange={onChange}
            
         />
      </div>
   );
};

export default NateFileUploader;
