/* global Log*/
import React from 'react';
import ProgressiveImage from "react-progressive-image";
import { Link } from "react-router-dom";
import Loader from 'react-loader-spinner'
import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';

import posts from "../posts/posts";
// import logo from "../assets/Logo.png"
// import pricetagOutline from "../assets/PricetagOutline.svg"

import styles from './Viewer.module.scss';
import Topbar from '../Topbar/Topbar';
import IconClose from '../assets/IconClose';
import { separateStr } from '../utils';


function vh(v) {
    var h = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);
    return (v * h) / 100;
}

function vw(v) {
    var w = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
    return (v * w) / 100;
}

function vmin(v) {
    return Math.min(vh(v), vw(v));
}

function vmax(v) {
    return Math.max(vh(v), vw(v));
}

const instagramify = str => {
    const txt = []
    let s = '';
    let i = 0;
    while (i < str.length) {
        if (str.charAt(i) === '@') {
            txt.push(s)
            let j = i
            while (j < str.length && str.charAt(j) !== ' ' && str.charAt(j) !== '\n') {
                j++
            }
            // console.warn("got-", str.substring(i, j), '-')
            txt.push(<a href={`https://instagram.com/${str.substring(i + 1, j)}/`} target="_blank" rel="noopener noreferrer" className={styles.unlink}>{str.substring(i, j)}</a>)
            i = j;
            s = str.charAt(j);
        } else {
            s += str.charAt(i);
        }
        i++;
    }
    txt.push(s)

    return (txt)
}

const notFound = (
    <div className={styles.layout}>
        <Link to="/">BACK</Link>
        COULD NOT FIND POST
    </div>)


const Viewer = ({ history, match }) => {
    let targetElement = null;
    const modalRef = React.useRef();
    const [scrollY, setScrollY] = React.useState(window.scrollY)

    React.useEffect(() => {

        // document.body.setAttribute("style", "overflow: hidden")
        document.addEventListener('keydown', onKeyDown);
        document.addEventListener('resize', onResize)
        // targetElement = modalRef.current;
        disableBodyScroll(modalRef.current);
        return (() => {

            // document.body.removeAttribute("style")
            document.removeEventListener('resize', onResize)
            document.removeEventListener('keydown', onKeyDown);
            clearAllBodyScrollLocks();
        })
    })

    const onResize = () => {
        setScrollY(window.scrollY)
    }

    const onKeyDown = (e) => {
        if (e.key === 'Escape') {
            history.push('/')
        }
    }

    // console.log("---", history)
    const id = match.params.postId;
    if (!id) return notFound
    const post = posts.find(p => p.id === id)
    if (!post) return notFound


    return (
        <div className={styles.modal} style={{ top: scrollY }} ref={modalRef}>
            <div className={styles.layout}>
                <Link to="/" className={styles.back}>
                    <IconClose className={styles.iconClose} />
                </Link>
                <Topbar />
                <div className={styles.photoContainer}>
                    <ProgressiveImage src={"https://res.cloudinary.com/dgxze1o8e/image/upload/q_auto:eco/" + id + ".jpg"} placeholder="">
                        {(src, loading) => {
                            return <img src={src} alt="" className={styles.photo}  style={{opacity: loading ? 0 : 1}}/>
                        }}
                    </ProgressiveImage>
                    {/* <img src={cl.url(id + '.jpg')} alt="" className={styles.photo} /> */}
                    {/* <img src={logo} className={styles.logoOverlay} alt="logo" /> */}
                </div>
                <div className={styles.captionWrapper}>
                    <span className={styles.caption}>{instagramify(post.caption)}</span>
                </div>
                <div className={styles.pricetagContainer}>
                    <img className={styles.pricetagOutline} src={"https://res.cloudinary.com/dgxze1o8e/image/upload/v1567763333/pricetag_outline_svg.svg"} alt="outline" />
                    <span className={styles.amount}>{`${separateStr(post.amount.toString())} $`}</span>
                </div>
            </div>
        </div>
    )
}

export default Viewer